import axios from "axios";

const url = window.location.href;
let BACKEND_URL;

if (url.includes("localhost") || url.includes("dev.b-key.kz")) {
  BACKEND_URL = "https://dev.b-key.kz/api/v1/";
} else {
  BACKEND_URL = "https://api.b-key.kz/api/v1/";
}

export const setApiKey = (api_key) => {
  instance.defaults.headers["X-API-KEY"] = api_key;
};

export const unsetApiKey = () => {
  delete instance.defaults.headers["Authorization"];
};

export const instance = axios.create({
  baseURL: BACKEND_URL,
});
